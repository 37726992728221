<template>
	<div class="record-add">
		<!-- 顶部栏 -->
		<!-- <nav-bar></nav-bar> -->

		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }"><span style="font-size: 17px;">首页</span></el-breadcrumb-item>
			<el-breadcrumb-item><span style="font-size: 17px;">笔录制作</span></el-breadcrumb-item>
		</el-breadcrumb>

		<!-- 操作按钮 -->
		<div class="record-controls">
			<div>
				<!-- 点击显示保存笔录信息  点击确认按钮 调用点击保存接口 -->
				<div @click="clickSave(1)">
					<img src="../assets/images/home_icon01.png" alt="">
					<span>保存</span>
				</div>
				<div @click="caseNumView()">
					<img src="../assets/images/home_icon02.png" alt="">
					<span>预览</span>
				</div>
				<div @click="caseNotificationDetail()">
					<img src="../assets/images/home_icon04.png" alt="">
					<span>告知书</span>
				</div>
				<div @click="caseEvidenceDetail()">
					<img src="../assets/images/home_icon05.png" alt="">
					<span>其他证据</span>
				</div>
				<div>
					<img src="../assets/images/home_icon03.png" alt="">
					<span>上传</span>
				</div>
				<!-- <div @click="clickSave(2)">
					<img src="../assets/images/home_icon06.png" alt="">
					<span>笔录签名</span>
				</div> -->
				<!-- clickSave(3) -->
				<div @click="clickPoliceSignature()">
					<img src="../assets/images/home_icon07.png" alt="">
					<span>民警签名</span>
				</div>
			</div>
			<div>
				<span v-if="!takeTime">00:00:00</span>
				<span v-else>{{$use.timeInfo(takeTime)}}</span>
				<span>用时</span>
			</div>
		</div>
		<!-- <video ref="video" controls></video> -->
		<!-- <button @click="startRecording">开始录制</button> -->
		<!-- <button @click="stopRecording">停止录制</button> -->
		<!-- 内容 -->
		<div class="record-container">
			<!-- 基本信息 -->
			<div class="record-baseinfo">
				<div class="record-header" v-if="!trtcVideo">
					基本信息
				</div>
				<div class="record-header2" v-else>
					<div class="tabs tab1" @click="tabIndex=0" :class="tabIndex==0?'tab_bg':''">基本信息</div>
					<div class="tabs tab2" @click="tabIndex=1" :class="tabIndex==1?'tab_bg':''">视频通话</div>
				</div>
				<div v-show="tabIndex!=0">
					<!-- trtc音视频通话 -->
					<div class="video_wrap">
						<div class="content">
							<!-- 本地视频 -->
							<div class="video_box">
								<notice-bar v-if="tabIndex!=0" style="position: absolute;z-index:999;top: 15px;"
									:text="noticeText" />
								<div id='local_stream' class="local-stream">
								</div>
							</div>


							<!-- 测试用 <div class="video_box">
								<notice-bar style="position: absolute;z-index:999;top: 120px;" :text="noticeText" />
								<div class="remote-stream" style="background-color: #55ffff;"></div>
							</div> -->
							<!-- 远端视频 -->
							<div class="video_box">
								<notice-bar v-if="tabIndex!=0&&remoteStreamList.length>0"
									style="position: absolute;z-index:999;top: 140px;" :text="noticeText" />
								<div v-for="(item, index) in remoteStreamList" :key="index" class="remote-stream">
									<div v-html="item.view" :class="{ 'distant-stream': item.view }"
										style="display: flex;justify-content: center;width: 100%;height: 100%;object-fit: contain;">
									</div>
								</div>
							</div>
						</div>
					</div>
					<div @click="logoutHandler()" class="video-call">结束视频通话</div>
				</div>
				<div v-show="tabIndex==0">
					<div class="record-title">笔录头</div>
					<div class="record-content">

						<!-- 次数 -->
						<div class="flex-row-between">
							<div class="font-title w-120">次数</div>
							<div class="flex-1">
								<el-input v-model="params.num" type="number" placeholder="请输入次数"></el-input>
							</div>
						</div>

						<!-- 开始时间/结束时间 -->
						<div class="record-choose-date">
							<div>
								<div class="font-title w-120">开始时间</div>
								<div>
									<el-date-picker @change="upStartTime" style="width: 3.1rem" v-model="begin_time"
										type="datetime" placeholder="选择开始时间"></el-date-picker>
								</div>
							</div>
							<div>
								<div class="font-title mr-10">结束时间</div>
								<div>
									<el-date-picker @change="upEndTime" style="width: 3.1rem;" v-model="end_time"
										type="datetime" placeholder="选择结束时间"></el-date-picker>
								</div>
							</div>
						</div>

						<!-- 地点 -->
						<div class="flex-row-between">
							<div class="font-title w-120">地点</div>
							<div class="flex-1">
								<el-input v-model="params.record_address" placeholder="请输入地点"></el-input>
							</div>
						</div>
						<div class="flex-row-between" v-for="item,index in police_ids" :key="index">
							<div class="flex-row-center">
								<div class="font-title w-120">询(讯)问人{{index+1}}</div>
								<div>
									<!-- <el-select style="width: 2rem" v-model="value" placeholder="请选择"
										@change="selectedChange($event, 0)">
										<el-option v-for="(item, index) in caseAskPoliceList" :key="index"
											:label="item.nickname" :value="item.id">
										</el-option>
									</el-select> -->
									<el-autocomplete style="width: 2rem" class="inline-input" value-key="nickname"
										v-model="item.nickname" :fetch-suggestions="querySearch" placeholder="请输入选择"
										@select="selectedChange($event,index,0)"
										@change="selectedChange($event,index,1)"></el-autocomplete>
								</div>
							</div>
							<div class="flex-row-center flex-1 ml-20">
								<div class="font-title mr-10">单位</div>
								<div class="flex-1">
									<el-input :value="item.work_address" placeholder="单位名称"></el-input>
								</div>
							</div>
						</div>
					</div>
					<div class="record-gap"></div>

					<div class="record-title">案件信息</div>
					<div class="record-content">
						<div class="case-category">
							<div class="font-title">案件类别</div>
							<div class="pl-20">
								<span class="text-black"
									v-if="selectedCaseCategory">{{ selectedCaseCategory.name }}</span>
								<span class="text-placeholder" v-else>请选择案件类别</span>
							</div>
							<div @click="$refs.chooseCaseCategoryRef.show()">选择</div>
						</div>
					</div>
					<div class="record-gap"></div>
					<div class="record-title">被询(讯)问人信息</div>
					<div class="record-content">
						<div class="case-category">
							<div class="font-title">选择被询(讯)问人</div>
							<div class="pl-20">
								<span class="text-black" v-if="params.name">{{ params.name }}</span>
								<span class="text-placeholder" v-else>请选择被询(讯)问人</span>
							</div>
							<div @click="$refs.selectInterrogatorRef.show()">选择</div>
						</div>

						<!-- 证件类型/证件号码-->
						<div class="flex-row-between">
							<div class="flex-row-center">
								<div class="font-title w-120">证件类型</div>
								<div>
									<el-select style="width: 2rem" v-model="params.card_type" placeholder="请选择"
										@change="e => params.card_type = e">
										<el-option v-for="item in optionsInfo.card" :key="item" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="flex-row-center flex-1 ml-20">
								<div class="font-title mr-10">证件号码</div>
								<div class="flex-1">
									<el-input v-model="params.card_num" placeholder="请输入"></el-input>
								</div>
							</div>
						</div>

						<!-- 人员类型/姓名-->
						<div class="flex-row-between">
							<div class="flex-row-center">
								<div class="font-title w-120">人员类型</div>
								<div>
									<el-select style="width: 2rem" v-model="params.personnel_type" placeholder="请选择"
										@change="e => params.personnel_type = e">
										<el-option v-for="item in optionsInfo.people" :key="item" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="flex-row-center flex-1 ml-20">
								<div class="font-title mr-10">姓名</div>
								<div class="flex-1">
									<el-input v-model="params.name" placeholder="请输入"></el-input>
								</div>
							</div>
						</div>

						<!-- 别名绰号/性别/出生日期 -->
						<div class="flex-row-between">
							<div class="flex-row-center">
								<div class="font-title w-120">别名绰号</div>
								<div class="flex-1">
									<el-input v-model="params.other_name" placeholder="请输入"></el-input>
								</div>
							</div>
							<div class="flex-row-center flex-1 ml-20">
								<div class="font-title mr-10">性别</div>
								<div>
									<el-select style="width: 2rem" v-model="params.gender" placeholder="请选择"
										@change="e => params.gender = e">
										<el-option v-for="item in genderArr" :key="item" :label="item" :value="item">
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="flex-row-center">
								<div class="font-title" style="margin:0 .125rem;">出生日期</div>
								<div>
									<el-date-picker style="width: 2rem;" @change="changeBirthday" v-model="birthday"
										type="date" placeholder="选择出生日期"></el-date-picker>
								</div>
							</div>
						</div>

						<!-- 文化程度/国籍/民族 -->
						<div class="flex-row-between">
							<div class="flex-row-center">
								<div class="font-title w-120">文化程度</div>
								<div class="flex-1">
									<el-select style="width: 2rem" v-model="params.degree" placeholder="请选择"
										@change="e => params.degree = e">
										<el-option v-for="item in optionsInfo.degree" :key="item" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="flex-row-center flex-1 ml-20">
								<div class="font-title mr-10">国籍</div>
								<div>
									<el-select style="width: 2rem" v-model="params.nation" placeholder="请选择"
										@change="e => params.nation = e">
										<el-option v-for="item in optionsInfo.nation" :key="item" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="flex-row-center">
								<div class="font-title" style="margin:0 .125rem;">民族</div>
								<div>
									<el-select style="width: 2rem" v-model="params.nationality" placeholder="请选择"
										@change="e => params.nationality = e">
										<el-option v-for="item in optionsInfo.nationality" :key="item" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</div>
							</div>
						</div>

						<!-- 政治面貌/是否人大代表-->
						<div class="flex-row-between">
							<div class="flex-row-center">
								<div class="font-title w-120">政治面貌</div>
								<div>
									<el-select style="width: 3rem" v-model="params.politics" placeholder="请选择"
										@change="e => params.politics = e">
										<el-option v-for="item in optionsInfo.politics" :key="item" :label="item"
											:value="item">
										</el-option>
									</el-select>
								</div>
							</div>
							<div class="flex-row-center ml-20">
								<div class="font-title mr-10">是否人大代表</div>
								<div>
									<el-select style="width:2.8rem" v-model="params.deputy" placeholder="请选择"
										@change="e => params.deputy = e">
										<el-option v-for="item in deputyArr" :key="item" :label="item.lel"
											:value="item">
										</el-option>
									</el-select>
								</div>
							</div>
						</div>

						<!-- 户籍地址 -->
						<div class="flex-row-between">
							<div class="font-title w-120">户籍地址</div>
							<div class="flex-1">
								<el-input v-model="params.register_address" placeholder="请输入"></el-input>
							</div>
						</div>

						<!-- 现住址 -->
						<div class="flex-row-between">
							<div class="font-title w-120">现住址</div>
							<div class="flex-1">
								<el-input v-model="params.live_address" placeholder="请输入"></el-input>
							</div>
						</div>

						<!-- 工作单位/联系电话-->
						<div class="flex-row-between">
							<div class="flex-row-center">
								<div class="font-title w-120">工作单位</div>
								<div class="flex-1">
									<el-input v-model="params.work_address" placeholder="请输入"></el-input>
								</div>
							</div>
							<div class="flex-row-center flex-1 ml-20">
								<div class="font-title mr-10">联系电话</div>
								<div class="flex-1">
									<el-input v-model="params.mobile" placeholder="请输入"></el-input>
								</div>
							</div>
						</div>

						<!-- 职业 -->
						<div class="flex-row-between">
							<div class="font-title w-120">职业</div>
							<div class="flex-1">
								<el-input v-model="params.woker" placeholder="请输入"></el-input>
							</div>
						</div>

						<!-- QQ号码/网络名-->
						<div class="flex-row-between">
							<div class="flex-row-center">
								<div class="font-title w-120">QQ号码</div>
								<div class="flex-1">
									<el-input v-model="params.QQ" placeholder="请输入"></el-input>
								</div>
							</div>
							<div class="flex-row-center flex-1 ml-20">
								<div class="font-title mr-10">网络名</div>
								<div class="flex-1">
									<el-input v-model="params.web_name" placeholder="请输入"></el-input>
								</div>
							</div>
						</div>

					</div>

					<div @click="createClient()" class="video-call">发起视频通话</div>

				</div>
			</div>


			<!-- 问答内容 -->
			<div class="record-questions-answers">
				<div class="qa-controls">
					<div>
						<div @click="$refs.importRecordTemplateRef.show()">从模板库导入</div>
						<div @click="$refs.retrieveCase.show()">找回笔录</div>
					</div>
					<div>
						<el-tooltip content="保存对话" placement="bottom" effect="light">
							<img @click="$refs.saveTemplateRef.show()" src="../assets/images/record_icon08.png" alt="">
						</el-tooltip>
						<el-tooltip content="删除空格" placement="bottom" effect="light">
							<img @click="delDialogueContent" src="../assets/images/record_icon09.png" alt="">
						</el-tooltip>
						<el-tooltip content="格式检查" placement="bottom" effect="light">
							<img @click="clickFormatInspect" src="../assets/images/record_icon10.png" alt="">
						</el-tooltip>
						<el-tooltip content="错别字检查" placement="bottom" effect="light">
							<img @click="caseContentCorrect" src="../assets/images/record_icon11.png" alt="">
						</el-tooltip>
						<img src="../assets/images/record_icon12.png" alt="">
						<el-tooltip content="查找替换" placement="bottom" effect="light">
							<img src="../assets/images/record_icon13.png" @click="$refs.findReplaceRef.show()" alt="">
						</el-tooltip>
					</div>
				</div>
				<div class="qa-controls_height"></div>
				<div class="qa-set">
					<!-- <div class="flex-row-center">
						<div class="font-title mr-10">字体</div>
						<div>
							<el-select style="width: 1.625rem" v-model="params2.font" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<div style="margin-left: .1875rem;">
							<el-select style="width: 1.625rem" v-model="params2.font_size" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div> -->

					<div class="flex-row-center ml-20">
						<div class="font-title mr-10">问题</div>
						<div>
							<el-color-picker v-model="params2.type0_color"></el-color-picker>
						</div>
					</div>

					<div class="flex-row-center ml-20">
						<div class="font-title mr-10">回答</div>
						<div>
							<el-color-picker v-model="params2.type1_color"></el-color-picker>
						</div>
					</div>

					<div class="flex-row-center ml-20">
						<div class="font-title mr-10">背景</div>
						<div>
							<el-color-picker v-model="params2.bg_color"></el-color-picker>
						</div>
					</div>
				</div>
				<div class="qa-list" :style="{background:params2.bg_color}">
					<div class="qa-item" v-for="(item, index) in params2.question" :key="index">
						<div>{{ index + 1 }}</div>
						<div style="margin-left: 0.3125rem;">{{item.type==0?'问：':'答：'}}</div>
						<el-input autosize
							:style="{ '--inputColor':item.type==0?params2.type0_color:params2.type1_color}"
							type="textarea" v-model="item.content" :ref="`content`+index"
							@keyup.enter.native="enterAddInput($event,item,index)"
							@blur="addInput($event,item,index)"></el-input>

					</div>
				</div>
			</div>
		</div>

		<!-- 选择案件类别 -->
		<choose-case-category ref="chooseCaseCategoryRef" @getItem="getCaseType"></choose-case-category>
		<!-- 选择被询(讯)问人 -->
		<select-interrogator ref="selectInterrogatorRef" @getItem="clickGetBeQuestioner"></select-interrogator>
		<!-- 保存到我的模板 -->
		<save-template @getType="getType" ref="saveTemplateRef"></save-template>
		<!-- 提示框 -->
		<custom-tips :list='formatInspectList' ref="customTipsRef"></custom-tips>
		<!-- 查找/替换 -->
		<find-replace @upContent="upContent" ref="findReplaceRef"></find-replace>
		<!-- 保存笔录 -->
		<save-record-success :signatureType="signatureType" @dialogClickConfirm="dialogClickConfirm"
			@amendTime="clickCaseNumTime" :params='params' :police_ids='police_ids'
			ref="saveRecordSuccessRef"></save-record-success>
		<!-- 签名版 -->
		<signature-edition @confirmSignature="confirmSignature" ref="signatureEditionRef"></signature-edition>
		<!-- 从模板导入 -->
		<import-record-template :LawCaseTypeList="LawCaseTypeList" @getToLead="getToLead"
			ref="importRecordTemplateRef"></import-record-template>
		<!-- 找回笔录 -->
		<retrieve-case ref="retrieveCase" :num_id="num_id" @getItem="clickRetrieveCase"></retrieve-case>
		<!-- 预览笔录 -->
		<preview-put-down @clickSave="clickSave" @clickPreview="clickPreview"
			:putDownData="{...params,question:params2.question}" ref="previewPutDown"></preview-put-down>
		<!-- 告知书 -->
		<notification-list @getItem="sendNotification" ref="notificationList"></notification-list>
		<!-- 错别字检查 -->
		<correct-tips :list="caseContentCorrectList" ref="correctTips"></correct-tips>

		<el-dialog title="上传证据" :visible.sync="proofDialogShow" center width="500px">
			<div style="width: 100%;height: 100%;padding-left: 50px;">
				<el-upload ref="upload" class="upload-demo" drag :action="`${$baseURL}/api/common/pdfUpload/`" multiple
					:limit="1" :on-success="upLoadFile" :on-remove="removeFile" :file-list="fileList" :accept="'.pdf'">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">
						只能上传PDF文件，且不超过500MB
					</div>
				</el-upload>
			</div>
			<div slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
				<custom-button type="cancel" @click.native="proofDialogCancel"></custom-button>
				<custom-button type="confirm" confirmText="发起签名" @click.native="proofDialogConfirm"
					style="margin-left: .975rem;"></custom-button>
			</div>
		</el-dialog>

		<!-- 预览笔录 -->
		<!-- <el-dialog title="笔录预览" :visible.sync="previewPdfImgShow" center width="900px" :show-close="false">
			<div style="width: 100%;max-height: 600px;height: 600px;overflow-y: auto;">
				<div style="position: relative;" v-for="(img,index) in previewPdfImg.data" :key="img">
					<img style="width: 100%;object-fit: contain;" :src="$baseURL+'/'+img" alt="" />
					<div class="signature_box" v-if="index<previewPdfImg.data.length-1">
						<img class="signature_box_signature" v-if="previewPdfImg.num.sign_image"
							:src="$baseURL+previewPdfImg.num.sign_image" alt="" />
						<img class="signature_box_signature" v-if="previewPdfImg.num.date_image"
							:src="$baseURL+previewPdfImg.num.date_image" alt="" />
					</div>
					<div style="position: absolute;bottom:10px;right:80px;">
						第{{index+1}}页 共{{previewPdfImg.data.length}}页
					</div>
				</div>
			</div>

			<div slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
				<custom-button confirmText="确定" type="confirm"
					@click.native="previewPdfImgShow = false"></custom-button>
				<custom-button style="margin-left: 20px;" confirmText="发起签名" type="confirm"
					@click.native="clickSave(2)"></custom-button>
			</div>
		</el-dialog> -->

		<!-- 预览告知书 -->
		<el-dialog title="告知书预览" :visible.sync="notificationDetailShow" center width="900px" :show-close="false">
			<div class="tips-content">
				<div style="width: 100%;height: 600px; padding-left: 50px;overflow-y: auto;text-align: center;">
					<div style="position: relative;" v-for="item in notificationDetail.watermark" :key="item">
						<img style="width: 100%;" :src="$baseURL+'/'+item" alt="" />
						<div
							style="display: flex;align-items: center;justify-content: center;position: absolute;bottom: 0px;width: 100%;">
							<img class="signature" v-if="notificationDetail.sign_image"
								:src="$baseURL+notificationDetail.sign_image" alt="" />
							<img class="signature" v-if="notificationDetail.date_image"
								:src="$baseURL+notificationDetail.date_image" alt="" />
						</div>
					</div>
				</div>
			</div>

			<div slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
				<custom-button confirmText="确定" type="confirm"
					@click.native="notificationDetailShow = false"></custom-button>
			</div>
		</el-dialog>

		<!-- 预览证据 -->
		<el-dialog title="证据预览" :visible.sync="previewShow" center width="70%">
			<div
				style="width: 100%;height: 100%;max-height: 700px; padding-left: 50px;overflow-y: auto;text-align: center;">
				<div style="position: relative;" v-for="item in evidenceDetail.file" :key="item">
					<img :src="$baseURL+'/'+item" alt="" />
				</div>
				<div style="text-align: center;">
					<img v-if="evidenceDetail.confirm_image"
						style="max-width: 200px;height: 100px;object-fit: contain;margin-right: 40px;transform: translate(-50%) rotate(-90deg);"
						:src="$baseURL+evidenceDetail.confirm_image" alt="" />
					<img v-if="evidenceDetail.confirm_image2"
						style="max-width: 200px;height: 100px;object-fit: contain;margin-right: 40px;transform: translate(-50%) rotate(-90deg);"
						:src="$baseURL+evidenceDetail.confirm_image2" alt="" />
					<img v-if="evidenceDetail.confirm_image3"
						style="max-width: 200px;height: 100px;object-fit: contain;margin-right: 40px;transform: translate(-50%) rotate(-90deg);"
						:src="$baseURL+evidenceDetail.confirm_image3" alt="" />
				</div>
				<div style="display: flex;align-items: center;justify-content: center;">
					<img class="signature" v-if="evidenceDetail.sign_image" :src="$baseURL+evidenceDetail.sign_image"
						alt="" />
					<img class="signature" v-if="evidenceDetail.date_image" :src="$baseURL+evidenceDetail.date_image"
						alt="" />
				</div>
			</div>
			<div slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
				<custom-button type="confirm" confirmText="确定" @click.native="previewShow = false"
					style="margin-left: .975rem;"></custom-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// 导入trtc
	import TRTC from 'trtc-js-sdk'
	import * as LibGenerateTestUserSig from '../utils/lib-generate-test-usersig-es.min.js'

	import NavBar from "../components/NavBar.vue";
	import ChooseCaseCategory from "../components/ChooseCaseCategory.vue";
	import SelectInterrogator from "../components/SelectInterrogator.vue";
	import SaveTemplate from "../components/SaveTemplate.vue";
	import CustomTips from "../components/CustomTips.vue";
	import FindReplace from "../components/FindReplace.vue";
	import SaveRecordSuccess from "../components/SaveRecordSuccess.vue";
	import SignatureEdition from "../components/SignatureEdition.vue";
	import ImportRecordTemplate from "../components/ImportRecordTemplate.vue";
	import retrieveCase from "../components/retrieveCase.vue";
	import previewPutDown from "../components/previewPutDown.vue";
	import notificationList from '../components/notificationList.vue'
	import correctTips from '../components/CorrectTips.vue'
	import NoticeBar from '../components/NoticeBar'

	export default {
		components: {
			'nav-bar': NavBar,
			'choose-case-category': ChooseCaseCategory,
			'select-interrogator': SelectInterrogator,
			'save-template': SaveTemplate,
			'custom-tips': CustomTips,
			'find-replace': FindReplace,
			'save-record-success': SaveRecordSuccess,
			'signature-edition': SignatureEdition,
			'import-record-template': ImportRecordTemplate,
			'retrieve-case': retrieveCase,
			'preview-put-down': previewPutDown,
			'notification-list': notificationList,
			'correct-tips': correctTips,
			'notice-bar': NoticeBar
		},
		data() {
			return {
				state1: '',
				state2: '',
				noticeText: '该案系在办案件，涉及警务工作秘密和国家秘密，严禁对取证内容录音、录像、截屏、转发、公开等，否则应承担法律责任。',
				num_id: "",
				tabIndex: 0,
				videoStartTimer: null, //取证用时的定时器
				takeTime: 0, //取证用时每秒加1
				//音视频通话
				trtcVideo: false,
				SDKAPPIDConfig: 1600026240,
				SECRETKEYConfig: 'e7d0afc690b8145b1d7f9da6bcdff26f3a877ac00b3b53e384516c10be919d6f',
				userId: '',
				roomId: null,
				client: '', // 客户端服务
				remoteStream: '', // 远方播放流
				localStream: '', // 本地流
				remoteStreamList: [], // 远端小视频
				// 录制桌面 保存到本地
				isRecording: false,
				mediaRecorder: null,
				recordedChunks: [],
				proofDialogShow: false, //上传证据对话框
				fileList: [], //上传的路径
				fileUrl: '', //证据路径
				// 笔录头信息
				params: {
					id: '', //笔录id
					num: 1, // 次数
					begin_time: '', // 开始时间
					end_time: '', // 结束时间
					record_address: '', // 询问地址
					case_classification: '', // 案件类别
					police_ids: '', // 询问人id
					people_id: '', // 被询问人id
					name: '', //被询问人名称
					personnel_type: '', // 人员类型
					card_type: '', // 姓名
					card_num: '', // 证件号
					other_name: '', // 别名
					gender: '', // 性别
					birthday: '', // 出生年月
					degree: '', // 文化
					nation: '', // 国籍
					nationality: '', // 民族
					politics: '', // 政治面貌
					deputy: '', // 人大代表
					register_address: '', // 户籍地址
					live_address: '', // 居住地址
					work_address: '', // 工作地址
					mobile: '', // 联系电话
					woker: '', // 职业
					QQ: '', // QQ
					web_name: '', // 网名
					status: 0,
					confirm_image: "",
					confirm_image2: "",
					confirm_image3: "",
					sign_image: "",
					police_image: "",
					date_image: '',
				},
				police_ids: [ //暂存询问人id数组
					{
						id: '',
						work_address: '',
						value: '',
						police_id: '',
						nickname: '',
					},
					{
						id: '',
						work_address: '',
						value: '',
						police_id: '',
						nickname: '',
					},
					{
						id: '',
						work_address: '',
						value: '',
						police_id: '',
						nickname: '',
					},
				],
				caseCategoryList: [], // 案件类别
				caseAskPoliceList: [], // 询问人列表
				optionsInfo: {}, // 选项
				selectedCaseCategory: null, // 已选案件类别
				LawCaseTypeList: [], //案件类别
				genderArr: ['男', '女'],
				deputyArr: ['是', '否'],
				// 默认选中询问人
				value: '', //询问人1
				value2: '', //询问人2
				value3: '', //询问人3
				begin_time: "", //页面回显开始时间
				end_time: "", //页面回显结束时间
				birthday: '', //出生日期
				input: '',
				options: {},
				// 对话问答笔录信息
				params2: {
					font: '', //字体
					font_size: '16', //字体大小
					//question.type0问  1答
					question: [{
						type: 0,
						content: '',
					}],
					type0_color: '#000000', //问颜色
					type1_color: '#000000', //答颜色
					bg_color: '#F5F9FE', //背景色
				},
				time: new Date().toLocaleTimeString(),
				timerId: null,
				formatInspectList: [], //检查格式 错误格式存储的数组
				signatureType: 1, // 点击1保存、2签名、3民警签名  用于模态框展示信息
				police_image: "", //民警签名
				caseContentCorrectList: [], //错别字检查
				notificationDetail: {}, //告知书预览
				notificationDetailShow: false,
				previewShow: false, //证据预览
				evidenceDetail: {},
				recording: false,
				stream: null,
				recorder: null,
				currentWebmData: null,
				downloadUrl: null,
				previewPdfImgShow: false, //预览笔录pdf弹出框
				previewPdfImg: { //预览笔录pdf图片
					data: [],
					num: {}
				},
				timeout: null,
			}
		},
		created() {
			console.log("this.$route.query.id", this.$route.query.id)
			if (this.$route.query.id) {
				this.num_id = this.$route.query.id
				this.params.id = this.$route.query.id //笔录id
				this.redactData(this.num_id)
			} else {
				this.caseNumInit()
				this.startTimer();
			}

			this.getOptions();
			this.getLawCaseTypeList()
			this.creatTrtc()
		},
		mounted() {
			// this.getCaseAskPoliceList();

			let curUser = JSON.parse(sessionStorage.getItem("ycqz:user"))
			console.log("curUser", curUser)
			this.value = curUser.nickname
			this.police_ids[0] = {
				...curUser
			};
			this.params.police_ids += curUser.police_id
			// this.getUnitName(0)
		},
		beforeRouteLeave(to, from, next) {
			this.stopTimer()
			this.client = null
			clearInterval(this.videoStartTimer);
			next(); // 继续导航
		},
		methods: {
			// 询问人输入搜索列表
			async querySearch(queryString, cb) {

				console.log(1111, queryString)
				// 调用 callback 返回建议列表的数据
				clearTimeout(this.timeout);
				// cb(results);
				const res = await this.$api.post('/police/case_data/case_ask_police_list', {
					search: queryString
				})
				if (res.code == 1) {
					res.data.list.forEach(item => {
						item.value = item.id + ''
						item.str = item.nickname + '' + item.username
					})

					this.caseAskPoliceList = res.data.list
					var restaurants = this.caseAskPoliceList;
					// var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
					
					this.timeout = setTimeout(() => {
						cb(restaurants);
					}, 100 * Math.random());
				} else {
					this.$use.msg(res.msg, 'error');
				}
			},
			createFilter(queryString) {
				return (restaurant) => {
					console.log("restaurant.str",restaurant.str,(restaurant.str.toLowerCase().indexOf(queryString.toLowerCase()) === 0))
					return (restaurant.str.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			// 点击选中的 询问人
			selectedChange(e, index, type) {
				console.log("========", e)
				// type 0点击 1监听
				// console.log(e, index)
				// caseAskPoliceList
				// 将选中的民警存储
				this.params.police_ids = ""

				this.caseAskPoliceList.forEach(item => {
					if (type == 0) {
						if (item.id == e.id) {
							this.police_ids[index] = item;
						}
					} else {
						if (item.nickname == e) {
							this.police_ids[index] = item;
						}
					}
				})
				this.police_ids.forEach(item => {
					this.params.police_ids += item.id + ','
				})
				this.params.police_ids = this.params.police_ids.slice(0, -1)
			},
			// 编辑笔录
			redactData(id) {
				this.$api.post('/police/case_data/case_num_view', {
						num_id: id
					}).then(res => {
						if (res.code == 1) {
							console.log("笔录记录", res)
							this.params = {
								...res.data
							}
							if (res.data.question) {
								this.params2.question = res.data.question
							}
							this.params2.type0_color = res.data.type0_color //问颜色
							this.params2.type1_color = res.data.type1_color //答颜色
							this.params2.bg_color = res.data.bg_color //背景色
							this.begin_time = res.data.begin_time * 1000 //页面回显开始时间
							this.end_time = res.data.end_time * 1000 //页面回显结束时间
							this.birthday = res.data.birthday
							let ids = res.data.police_ids.split(",")
							// this.police_ids = 
							this.LawCaseTypeList.forEach(item => {
								if (item.id == res.data.case_classification) {
									this.selectedCaseCategory = item
								}
							})

							ids.forEach((item, i) => {
								this.caseAskPoliceList.forEach(item2 => {
									if (item2.id == item) {
										this.police_ids[i] = item2;
									}
								})
								if (i == 0) {
									console.log("i0", item)
									this.value = {
										id: item
									} //询问人1
								} else if (i == 1) {
									console.log("i1", item)
									this.value2 = {
										id: item
									} //询问人2
								} else if (i == 2) {
									console.log("i2")
									this.value3 = {
										id: item
									} //询问人3
								}
							})
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg, 'error');
					})
			},

			// 初始化trtc
			creatTrtc() {
				// return
				this.userId = 'police_' + this.$store.state.userInfo.id
				// 获取签名
				this.$api.post('/police/case_data/trtcUsersig').then(res => {
						if (res.code == 1) {
							const sdkAppId = this.SDKAPPIDConfig
							const userSig = res.data.usersig
							const userId = this.userId
							this.client = TRTC.createClient({
								mode: 'rtc', // 实时音视频通话
								sdkAppId,
								userId,
								userSig,
							})
							console.log("初始化trtc", this.client)
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '获取签名失败', 'error')
					})
			},
			// 点击预览笔录
			clickPreview() {
				window.open('https://c.ynshedingqiang.cn/index/index/num2?num_id=' + this.num_id)
			},
			// 可以绑定在按钮上，点击按钮，进行视频通话
			async createClient() {
				if (!this.params.people_id) {
					return this.$use.msg('请选择被问询人信息', 'warning')
				}
				console.log("this.params.people_id", this.params.people_id)
				// 获取房间id
				const res = await this.$api.post('/police/case_data/trtcRoomCreate', {
					people_id: this.params.people_id
				})
				if (res.code == 1) {
					this.roomId = res.data.room_id
					console.log("获取房间id", res, res.data.room_id)
					this.localStream = ''
					this.remoteStreamList = []
					// 定时器 记录用时
					this.startTakeTime()
					// 注册远程监听
					this.subscribeStream(this.client)
					// 注册监听事件 如，房间关闭
					this.handleEvents(this.client)
					// 注册监听用户退房事件
					this.userLeft(this.client)
					// 初始化成功后才能加入房间
					this.joinRoom(this.client, +this.roomId)
				} else {
					this.$use.msg(err.msg || '获取房间id失败', 'error')
				}
			},
			// 监听用户进入房间
			userJoined() {
				client.on('userJoined', (event) => {
					const userId = event.userId;
					console.log(`用户${userId}已加入房间`);
					// 在这里可以添加您希望在有新用户加入时执行的代码逻辑
				});
			},
			// 监听用户离开房间
			userLeft(client) {
				// 监听远端流移除事件
				client.on('stream-removed', event => {
					const remoteStream = event.stream;
					console.log('远端流移除: ' + remoteStream.getId());
					// 从列表中移除对应的远端流
					this.remoteStreamList = this.remoteStreamList.filter(item => item.id !== remoteStream.getId());
					// 销毁远端流
					remoteStream.stop();
					this.remoteStreamList = [];
					// 如果需要，这里还可以做一些UI清理工作，如隐藏或移除对应的DOM元素
				});
			},

			// 订阅远端流--加入房间之前
			subscribeStream(client) {


				client.on('stream-added', event => {
					const remoteStream = event.stream
					console.log('远端流增加: ' + remoteStream.getId())
					// 订阅远端流
					client.subscribe(remoteStream)
				})
			},

			handleEvents(client) {
				client.on('stream-subscribed', event => {
					const remoteStream = event.stream
					console.log("远端流", event)
					console.log('远端流订阅成功：' + remoteStream.getId())
					// 创建远端流标签，因为id是动态的，所以动态创建，用了v-html
					let remoteStreamItem = `<div id="${'remote_stream-' + remoteStream.getId()}"></div>`
					let isExist = this.remoteStreamList.find(item => item.userId === remoteStream.getUserId())
					if (!isExist) {
						this.remoteStreamList.push({
							id: remoteStream.getId(),
							userId: remoteStream.getUserId(),
							view: remoteStreamItem,
							hasVideo: true, // 是否开启了摄像头
							hasMic: true // 是否开启了麦克风
						})

						// 做了dom操作 需要使用$nextTick(),否则找不到创建的标签无法进行播放
						this.$nextTick(() => {
							//播放远端流
							remoteStream.play('remote_stream-' + remoteStream.getId())
						})
					}
				})
			},

			// 加入房间
			joinRoom(client, roomId) {
				client.join({
						roomId: roomId
					})
					.catch(error => {
						console.error('进房失败 ' + error)
					})
					.then(() => {
						// 进房成功后 打开视频
						this.trtcVideo = true
						this.tabIndex = 1
						console.log('进房成功')
						this.startRecording()
						// 发送进房邀请
						this.trtcRoomOnline()
						// 创建本地流
						this.createStream(this.userId)
					})
			},
			// 进房成功发送进房邀请
			trtcRoomOnline() {
				this.$api.post('/police/case_data/trtcRoomOnline', {
						room_id: this.roomId,
					}).then(res => {
						if (res.code == 1) {
							console.log("发送成功")
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '发送邀请失败', 'error')
					})
			},
			// 创建本地音频流
			async createStream(userId) {
				const localStream = TRTC.createStream({
					userId,
					audio: true,
					video: true
				})
				// 设置视频属性 Profile 为 '480p'
				localStream.setVideoProfile('480p')

				this.localStream = localStream

				try {
					await localStream
						.initialize()
						// .catch(error => {
						//   console.error('初始化本地流失败 ' + error)
						// })
						.then(() => {
							console.log('初始化本地流成功')
							// 创建好后才能播放 本地流播放 local_stream 是div的id
							localStream.play('local_stream')
							// 创建好后才能发布
							this.publishStream(localStream, this.client)
						})
				} catch (error) {
					switch (error.name) {
						case 'NotReadableError':
							alert(
								'暂时无法访问摄像头/麦克风，请确保系统允许当前浏览器访问摄像头/麦克风，并且没有其他应用占用摄像头/麦克风'
							)
							break
						case 'NotAllowedError':
							if (error.message === 'Permission denied by system') {
								alert('请确保系统允许当前浏览器访问摄像头/麦克风')
							} else {
								console.log('User refused to share the screen')
								alert('请确保系统允许当前浏览器访问摄像头/麦克风')
							}
							break
						case 'NotFoundError':
							alert(
								'浏览器获取不到摄像头/麦克风设备，请检查设备连接并且确保系统允许当前浏览器访问摄像头/麦克风'
							)
							break
						default:
							break
					}
				}
			},
			// 发布本地音视频流
			publishStream(localStream, client) {
				client
					.publish(localStream)
					.catch(error => {
						console.error('本地流发布失败 ' + error)
					})
					.then(() => {
						console.log('本地流发布成功')
					})
			},

			// 退出房间
			logoutHandler() {
				this.leaveRoom(this.client)
			},
			leaveRoom(client) {
				client
					.leave()
					.then(() => {
						this.trtcVideo = false
						this.tabIndex = 0
						clearInterval(this.videoStartTimer);
						console.log('退房成功')
						this.outRoom()
						// 停止本地流，关闭本地流内部的音视频播放器
						this.localStream.stop()
						// 关闭本地流，释放摄像头和麦克风访问权限
						this.localStream.close()
						this.localStream = null
						// 退房成功，可再次调用client.join重新进房开启新的通话。
						this.stopRecording()
					})
					.catch(error => {
						console.error('退房失败 ' + error)
						// 错误不可恢复，需要刷新页面。
					})
			},

			outRoom() {
				this.$api.post('/police/case_data/closeRoom', {
					room_id: this.roomId
				}).then(res => {
					if (res.code == 1) {
						console.log("res退房成功")
					} else {
						this.$use.msg(res.msg, 'error');
					}
				})

			},

			// 点击开始视频通话
			startTakeTime() {
				this.takeTime = 0
				this.videoStartTimer = setInterval(() => {
					this.takeTime += 1
				}, 1000)
			},

			// 每分钟调用一次保存
			startTimer() {
				if (this.timerId) return;
				this.timerId = setInterval(() => {
					this.time = new Date().toLocaleTimeString();
					this.caseNumSave()
				}, 60000);
			},

			stopTimer() {
				if (this.timerId) {
					clearInterval(this.timerId);
					this.timerId = null;
				}
			},
			// 开始笔录 获取笔录id
			caseNumInit() {
				this.$api.post('/police/case_data/case_num_init').then(res => {
						if (res.code == 1) {
							this.num_id = res.data.num_id
							this.params.id = res.data.num_id //笔录id
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '创建笔录失败', 'error');
					})
			},
			clickNumView() {
				const loading = this.$loading({
					lock: true,
					text: '加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				this.$api.post('/api/index/num_view', {
						num_id: this.num_id,
						fresh: 1,
					}).then(res => {
						console.log("预览笔录", res)
						if (res.code == 1) {
							this.previewPdfImg = res.data
							this.previewPdfImgShow = true
						} else {
							this.$use.msg(res.msg, 'error');
						}
						loading.close();
					})
					.catch(err => {
						loading.close();
						this.$use.msg(err.msg || '获取笔录信息失败', 'error');
					})
			},

			// 笔录预览  获取签名信息
			caseNumView() {
				// this.caseNumSave(0) this.num_id

				this.$api.post('/police/case_data/case_num_view', {
						num_id: this.num_id
					}).then(res => {
						if (res.code == 1) {
							console.log('笔录预览')
							this.params.sign_image = res.data.sign_image
							this.params.date_image = res.data.date_image
							this.params.confirm_image = res.data.confirm_image
							this.params.confirm_image2 = res.data.confirm_image2
							this.params.confirm_image3 = res.data.confirm_image3
							this.params.police_image = res.data.police_image
							setTimeout(() => {
								this.$refs.previewPutDown.show()
							}, 500)
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '获取笔录信息失败', 'error');
					})
			},

			clickRetrieveCase(caseRetrieve) {
				let pageId = this.num_id
				this.params = caseRetrieve.content
				this.params.id = pageId
				this.params2 = caseRetrieve.content.template
				this.params2.id = pageId
				this.begin_time = caseRetrieve.content.begin_time * 1000 //页面回显开始时间
				this.end_time = caseRetrieve.content.end_time * 1000 //页面回显结束时间
				this.birthday = caseRetrieve.content.birthday
				let ids = caseRetrieve.content.police_ids.split(",")
				// this.police_ids = 
				this.LawCaseTypeList.forEach(item => {
					if (item.id == caseRetrieve.content.case_classification) {
						this.selectedCaseCategory = item
					}
				})

				ids.forEach((item, i) => {

					this.caseAskPoliceList.forEach(item2 => {
						if (item2.id == item) {
							this.police_ids[i] = item2;
						}
					})

					if (i == 0) {
						console.log("i0", item)
						this.value = {
							id: item
						} //询问人1
					} else if (i == 1) {
						console.log("i1", item)
						this.value2 = {
							id: item
						} //询问人2
					} else if (i == 2) {
						console.log("i2")
						this.value3 = {
							id: item
						} //询问人3
					}
				})
			},
			// 点击修正结束时间
			clickCaseNumTime() {
				if (!this.params.end_time) {
					this.$use.msg('清先选择结束时间', 'warning');
					return
				}

				let par = {
					num_id: this.num_id,
					end_time: this.params.end_time,
				}
				this.$api.post('/police/case_data/case_num_time', par).then(res => {
						if (res.code == 1) {
							console.log("修正结束时间", res)
							this.end_time = res.data
							this.params.end_time = res.data
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '修正失败', 'error');
					})
			},
			// 弹窗点击关闭笔录
			dialogClickConfirm(type) {
				console.log(":type", type)
				if (type != 1 && type != 2) {
					this.$refs.signatureEditionRef.show()
				}
				if (type == 2) {
					this.sendSignature()
				}
			},
			// 民警签名
			clickPoliceSignature() {
				this.$use.msg('请打印后签名', 'warning');
			},
			// 点击保存笔录  或点击其他图标有保存操作的逻辑
			clickSave(type) {
				this.signatureType = type
				if (type == 1) {
					if (!this.params.begin_time) {
						this.$use.msg('请选择开始时间', 'error');
					} else if (!this.params.record_address) {
						this.$use.msg('请填写询问地址', 'error');
					} else if (!this.params.case_classification) {
						this.$use.msg('请选择案件类别', 'error');
					} else if (!this.params.police_ids) {
						this.$use.msg('请选择询问人', 'error');
					} else if (!this.params.people_id) {
						this.$use.msg('请选择被询问人', 'error');
					} else if (!this.params.name) {
						this.$use.msg('请填写被询问人姓名', 'error');
					} else if (!this.params.personnel_type) {
						this.$use.msg('请选择人员类型', 'error');
					} else if (!this.params.card_type) {
						this.$use.msg('请选择证件类别', 'error');
					} else if (!this.params.gender) {
						this.$use.msg('请选择被询问人性别', 'error');
					} else if (!this.params.birthday) {
						this.$use.msg('请选择被询问人出生日期', 'error');
					} else if (!this.params.degree) {
						this.$use.msg('请选择被询问人文化程度', 'error');
					} else if (!this.params.nation) {
						this.$use.msg('请选择被询问人国籍', 'error');
					} else if (!this.params.nationality) {
						this.$use.msg('请选择被询问人民族', 'error');
					} else if (!this.params.politics) {
						this.$use.msg('请选择被询问人政治面貌', 'error');
					} else if (!this.params.deputy) {
						this.$use.msg('请选择被询问人是否人大代表', 'error');
					} else if (!this.params.register_address) {
						this.$use.msg('请填写被询问人户籍地址', 'error');
					} else if (!this.params.live_address) {
						this.$use.msg('请填写被询问人居住地址', 'error');
					} else if (!this.params.work_address) {
						this.$use.msg('请填写被询问人工作地址', 'error');
					} else if (!this.params.mobile) {
						this.$use.msg('请填写被询问人联系电话', 'error');
					}
					setTimeout(() => {
						this.caseNumSave('click')
					}, 800)
				} else {
					this.caseNumSave()
					if (type == 3 && this.params.status == 1) {
						this.$use.msg('问询人还未签名', 'warning');
						return
					}
					this.$refs.saveRecordSuccessRef.show()
				}
			},
			// 保存笔录 每分钟保存一次
			caseNumSave(type) {
				let par = {
					...this.params,
					template: this.params2
				}
				// console.log("保存临时笔录", par)
				this.$api.post('/police/case_data/case_num_save', par).then(res => {
						if (res.code == 1) {
							if (type == 'click') {
								this.$use.msg('保存成功', 'success');
							} else if (type == 0) {
								this.clickNumView()
							}
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '保存笔录失败', 'error');
					})
			},
			// 签名

			// 发送笔录签名
			sendSignature() {
				this.$api.post('/police/case_data/case_num_sign', {
						num_id: this.num_id
					}).then(res => {
						if (res.code == 1 && res.data.code != 1003) {
							this.params.status = 2
							console.log("发送签名", res)
							this.$use.msg('发送成功', 'success');
						} else {
							this.$use.msg('请勿重复发送', 'error')
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '发送失败', 'error')
					})
			},
			// 民警签名
			caseNumPoliceSign() {
				this.$api.post('/police/case_data/case_num_police_sign', {
						num_id: this.num_id,
						police_image: this.police_image
					}).then(res => {
						if (res.code == 1) {
							this.params.status = 3
							console.log("上传民警签名", res)
							this.$use.msg('民警签名成功', 'success')
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg, 'error')
					})
			},
			// 上传签名  组件回调  回调数据中有签名
			confirmSignature(data) {
				console.log("签名图片", data)
				if (this.signatureType == 3) {
					// 民警签名
					this.police_image = data.img.url
					this.caseNumPoliceSign()
				} else {
					// 其他签名

				}
			},
			// 发送告知书 签名
			sendNotification(event) {
				if (!this.params.people_id) {
					this.$use.msg('请选择被询问人', 'error')
					return
				}

				// notification_id 告知书id  people_id被询问人id
				this.$api.post('/police/case_data/case_notification_send', {
						num_id: this.num_id,
						notification_id: event.id,
						people_id: this.params.people_id
					}).then(res => {
						if (res.code == 1) {
							console.log('发送告知书', res)
							this.$use.msg('发送成功', 'success');
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg, 'error')
					})
			},
			// 上传证据 文件
			upLoadFile(response, file, fileList) {
				console.log("上传成功", response)
				if (response.code == 1) {
					this.fileUrl = response.data.url
				} else {
					this.fileList = []
				}
			},
			removeFile(file, fileList) {
				this.fileUrl = ""
				this.fileList = []
				console.log("删除", file, fileList)
			},
			// 关闭上传证据 清空上传文件
			proofDialogCancel() {
				this.fileList = []
				this.fileUrl = ""
				this.proofDialogShow = false
			},
			// 点击发起证据签名
			proofDialogConfirm() {
				if (!this.fileUrl) {
					return this.$use.msg('请上传证据', 'warning')
				}
				this.$api.post('/police/case_data/case_evidence_send', {
						num_id: this.num_id, //笔录id
						people_id: this.params.people_id, //被询问人id
						file: this.fileUrl, //证据文件链接
					})
					.then(res => {
						if (res.code == 1) {
							this.$use.msg('发送成功', 'success')
							this.proofDialogCancel()
						} else {
							this.$use.msg(res.msg, 'error');
						}

					})

			},
			// 预览告知书
			caseNotificationDetail() {
				this.$api.post('/police/case_data/case_notification_detail', {
						num_id: this.num_id
					})
					.then(res => {
						if (res.code == 1) {
							this.notificationDetail = res.data
							if (res.data.status) {
								this.notificationDetailShow = true
							} else {
								this.$use.msg('被询问人还未签名，暂无法查看', 'warning')
							}
						} else {
							this.$refs.notificationList.show()
							// this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						console.log('err', err)
						this.$use.msg(err.msg, 'error')
					})
			},
			// 预览证据
			caseEvidenceDetail() {
				this.$api.post('/police/case_data/case_evidence_detail', {
						num_id: this.num_id
					})
					.then(res => {
						console.log("预览证据", res)
						if (res.code == 1) {
							this.evidenceDetail = res.data
							if (res.data.status) {
								this.previewShow = true
							}
							// else{
							// 	this.$use.msg('被询问人还未签名，暂无法查看', 'warning')
							// }
						} else {
							this.proofDialogShow = true
						}
					})
					.catch(err => {
						console.log('err', err)
						this.$use.msg(err.msg, 'error')
					})
			},


			// 获取options 下拉选择列表
			async getOptions() {
				const res = await this.$api.post('/police/case_data/case_num_options')
				if (res.code == 1) {
					this.optionsInfo = res.data;
				} else {
					this.$use.msg(res.msg, 'error');
				}
			},
			// 选择开始时间
			upStartTime(e) {
				this.params.begin_time = this.$use.dateTimeInfo(e)
			},
			// 选择结束时间
			upEndTime(e) {
				this.params.end_time = this.$use.dateTimeInfo(e)
			},
			// 选择出生日期
			changeBirthday(e) {
				this.params.birthday = this.$use.dateTimeInfo(e)
			},
			// 获取询问人员列表
			async getCaseAskPoliceList(search = '') {
				const res = await this.$api.post('/police/case_data/case_ask_police_list', {
					search,
				})
				if (res.code == 1) {
					res.data.list.forEach(item => {
						item.value = item.nickname
					})
					this.caseAskPoliceList = res.data.list
				} else {
					this.$use.msg(res.msg, 'error');
				}
			},
			// 点击选中询问人
			clickGetBeQuestioner(beQuestioner) {
				// console.log('被询问人', beQuestioner)
				// 被询问人
				this.params.people_id = beQuestioner.id //id
				this.params.name = beQuestioner.real_name //名称
				this.params.card_type = beQuestioner.card_type //证件类型
				this.params.card_num = beQuestioner.card_num //证件号码
				this.params.work_address = beQuestioner.work_address //工作地址
				this.params.mobile = beQuestioner.mobile //联系电话
				this.params.gender = beQuestioner.gender
				this.params.birthday = beQuestioner.birthday
				this.birthday = beQuestioner.birthday
			},
			// 给询问人单位赋值
			getUnitName(index) {
				// console.log("===>",this.police_ids[index])
				if (this.police_ids[index]) {
					let obj = this.caseAskPoliceList.find(item => item.id == this.police_ids[index].id);
					if (obj) {
						return obj.work_address
					}
				}
			},
			// 获取选中的案件类别
			getCaseType(e) {
				// console.log("案件类别",e)
				this.selectedCaseCategory = e
				this.params.case_classification = e.id
			},
			// 添加问或答输入框
			addInput(e, row, index) {
				// 如果是在最后一行并且最后一行有值 则添加一个行
				// if (this.params2.question.length - 1 == index && this.params2.question[index].content.trim() !== '') {
				// 	let refTitle = 'content' + index
				// 	let refTitle2 = 'content' + (index + 1)

				// 	this.params2.question.push({
				// 		type: row.type == 0 ? 1 : 0,
				// 		content: '',
				// 	})

				// 	this.$refs[refTitle][0].blur();
				// 	setTimeout(() => {
				// 		this.$refs[refTitle2][0].focus();
				// 	}, 10)
				// }
			},
			// 按下回车键  添加输入框
			enterAddInput(e, row, index) {
				// 如果是在最后一行点击的回车键
				if (this.params2.question.length - 1 == index) {
					let refTitle = 'content' + index
					let refTitle2 = 'content' + (index + 1)

					this.params2.question.push({
						type: row.type == 0 ? 1 : 0,
						content: '',
					})

					this.$refs[refTitle][0].blur();
					setTimeout(() => {
						this.$refs[refTitle2][0].focus();
					}, 10)
				}
			},
			// 点击保存到模版库参数
			getType(name, lawCaseType) {
				let par = {
					template_name: name, //模版名称
					classification_id: lawCaseType.id, //案件类型id
					font_size: this.params2.font_size, //字号
					font: this.params2.font, //字体
					type0_color: this.params2.type0_color, //问颜色
					type1_color: this.params2.type1_color, //答颜色
					bg_color: this.params2.bg_color, //背景色
					question: this.params2.question, //对话内容
				}
				this.setLawCaseTemplate(par)
			},
			// 添加到模版库 外围参数 比如案件id等
			setLawCaseTemplate(par) {
				this.$api.post('/police/case_data/case_template_add', par).then(res => {
						if (res.code == 1) {
							this.$use.msg('保存成功', 'success');
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '保存失败', 'error');
					})
			},
			// 获取案件类型
			getLawCaseTypeList() {
				this.$api.post('/police/case_data/case_classification_list', {}).then(res => {
						if (res.code == 1) {
							this.LawCaseTypeList = res.data.list;
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg, 'error');
					})
			},
			// 点击从模版导入
			getToLead(data) {
				this.params2.font = data.font //字体
				this.params2.font_size = data.font_size //字体大小
				this.params2.type0_color = data.type0_color //问颜色
				this.params2.type1_color = data.type1_color //答颜色
				console.log("this.params2.type1_color", this.params2.type1_color)
				this.params2.bg_color = data.bg_color //背景色
				this.params2.template_name = data.template_name
				this.params.case_classification = data.classification_id //案件类型id
				this.selectedCaseCategory = data.classificationName //案件类型名称
				console.log('模版导入data', data)
				if (data.question && data.question.length > 0) {
					this.params2.question = data.question
				} else {
					this.params2.question = [{
						type: 0,
						content: '',
					}]
				}
			},
			// 点击删除对话内容的空格
			delDialogueContent() {
				if (this.params2.question && this.params2.question.length > 0) {
					this.params2.question.forEach(item => {
						let str = item.content.replace(/\s+/g, '')
						if (str != item.content) {
							item.content = JSON.parse(JSON.stringify(str))
						}
					})
					this.$use.msg('删除成功', 'success')
				} else {
					this.$use.msg('暂无问答内容', 'warning')
				}
			},
			// 点击为当前对话做格式检查
			clickFormatInspect() {
				this.formatInspectList = []
				if (this.params2.question && this.params2.question.length > 1) {
					for (let i = 1; i < this.params2.question.length; i++) {
						// console.log(this.params2.question[i].type, this.params2.question[i - 1].type)
						if (this.params2.question[i].type == this.params2.question[i - 1].type) {
							this.formatInspectList.push({
								index: i,
								type: this.params2.question[i].type
							})
							i += 2
						}
					}
					if (this.formatInspectList.length > 0) {
						this.$refs.customTipsRef.show()
					} else {
						this.$use.msg('格式正确', 'success')
					}
				} else {
					this.$use.msg('暂无问答内容', 'warning')
				}
			},
			// 内容替换
			upContent(upWord1, upWord2) {
				let haveType = false; //判断有没有当前要替换的内容 false没有 true有
				if (this.params2.question && this.params2.question.length > 0) {
					this.params2.question.forEach(item => {
						if (item.content.trim() != '' && item.content.includes(upWord1)) {
							const newText = item.content.replace(new RegExp(upWord1, 'g'), upWord2);
							item.content = JSON.parse(JSON.stringify(newText))
							haveType = true
						}
					})
					if (!haveType) {
						this.$use.msg('暂无要修改的内容', 'warning');
					}
				} else {
					this.$use.msg('暂无要修改的内容', 'warning');
				}
			},
			// 错别字检查
			caseContentCorrect() {
				if (this.params2.question.length == 1 && !this.params2.question[0].content) {
					this.$use.msg('暂无问答内容', 'warning')
					return
				}
				let strList = []
				this.params2.question.forEach(item => {
					strList.push(item.content)
				})
				console.log('strList', strList)
				this.$api.post('/police/case_data/case_template_content_correct', {
						string: strList
					}).then(res => {
						if (res.code == 1) {
							if (res.data.CorrectionList.length > 0) {
								this.caseContentCorrectList = res.data.CorrectionList
								this.$refs.correctTips.show()
							} else {
								this.$use.msg('暂无错别字', 'success')
							}
						} else {
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '', 'error')
					})
			},


			async startRecording() {
				try {
					this.recording = true;

					// 获取屏幕视频流
					const videoStream = await navigator.mediaDevices.getDisplayMedia({
						video: true
					});

					// 获取音频流
					const audioStreamPromise = navigator.mediaDevices.getUserMedia({
						audio: true
					});

					// 当音频流获取成功后，合并视频和音频流
					audioStreamPromise.then(audioStream => {
						this.stream = new MediaStream([...videoStream.getTracks(), ...audioStream
							.getTracks()
						]);
						this.recorder = new MediaRecorder(this.stream, {
							mimeType: 'video/webm;codecs=vp9,opus'
						});

						const recordedChunks = [];
						this.recorder.ondataavailable = e => recordedChunks.push(e.data);
						this.recorder.onstop = async () => {
							const completeBlob = new Blob(recordedChunks, {
								type: 'video/webm'
							});
							await this.saveToLocal(completeBlob);
						};

						this.recorder.start();
					}).catch(error => {
						console.error('获取音频流失败', error);
						// 处理错误，可能需要停止视频流或通知用户
					});
				} catch (error) {
					console.error('录制开始时出错', error);
					this.recording = false;
				}
			},

			async stopRecording() {
				if (this.recorder && this.recorder.state === 'recording') {
					this.recorder.stop();
					this.recording = false;
				}
			},

			async saveToLocal(blob) {
				this.downloadUrl = URL.createObjectURL(blob);
				let fileName = "aaaaa.webm"
				// let fileName =
				// `${this.params.name}${this.selectedCaseCategory.name}案第${this.params.num}询问笔录${this.$use.dateTimeInfoToo(new Date().getTime())}.webm`;
				console.log("========》", fileName)

				const link = document.createElement('a');
				link.href = this.downloadUrl;
				link.download = fileName;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				URL.revokeObjectURL(this.downloadUrl);
				this.downloadUrl = null;
			},


		}
	}
</script>
<style>

</style>
<style lang="scss">
	body {
		background-color: #F8F8F8;
	}

	.video_box {
		position: relative;
		width: 500px;
		margin: 0 auto;
	}

	.video_box_text {
		position: absolute;
		z-index: 999;
	}

	.el-carousel__item .medium {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 200px;
		margin: 0;
	}

	.signature {
		max-width: 200px;
		height: 100px;
		object-fit: contain;
		transform: translate(-50%) rotate(-90deg);
		margin: 0 30px;
	}

	.record-add {
		background: #F8F8F8;
		width: 100%;
		min-height: 100vh;
		max-height: 100%;
		padding: .375rem;


		.local-stream {
			width: 500px;
			height: 300px;
			margin: 20px auto 0;
			border-radius: 10px;
		}

		.remote-stream {
			width: 300px;
			height: 500px;
			margin: -90px auto 0;
			border-radius: 10px;
			transform: rotate(90deg);
			object-fit: contain;
		}


		.font-title {
			font-size: .25rem;
			color: #333333;
			white-space: nowrap;
		}

		.text-placeholder {
			font-size: .23rem;
			color: #C0C4CC;
		}

		.text-black {
			font-size: .23rem;
			color: #333333;
		}

		.w-120 {
			width: 1.5rem;
		}

		.mr-10 {
			margin-right: .125rem;
		}

		.ml-20 {
			margin-left: .25rem;
		}

		.pl-20 {
			padding-left: .25rem;
		}

		.el-input__inner {
			background-color: #F5F5F5;
			border: none;
			font-size: .23rem;
		}

		.record-container {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-top: .3125rem;


			.record-questions-answers {
				width: 12.75rem;
				height: 9rem;
				background: #FFFFFF;
				border-radius: .1875rem;
				padding: .25rem;
				overflow-y: auto;
				overflow-wrap: break-word;

				.qa-list {
					width: 12.2625rem;
					// height: 23.5625rem;
					background: #F5F9FE;
					border-radius: .1875rem;

					.qa-item {
						display: flex;
						align-items: center;
						min-height: .75rem;
						border-bottom: .0125rem solid #D1E5FF;

						.el-input__inner {
							background-color: rgba(0, 0, 0, 0);
							padding: 0;
							color: var(--inputColor); //使用css变量 注意变量前需要加 --
						}

						.el-textarea__inner {
							color: var(--inputColor);
							background-color: rgba(0, 0, 0, 0);
							padding: 0;
							border: none;
						}

						>div {
							font-size: .2rem;
							color: #333333;

							&:first-child {
								display: flex;
								align-items: center;
								justify-content: center;
								width: .75rem;
								height: .75rem;
								border-right: .0125rem solid #D1E5FF;
							}

							&:last-child {
								flex: 1;
								padding-left: .25rem;
							}
						}
					}
				}

				.qa-set {
					display: flex;
					align-items: center;
					margin: .1875rem 0 .25rem;
				}

				.qa-controls {
					width: 100%;
					display: flex;
					align-items: center;

					>div {
						&:first-child {
							display: flex;
							align-items: center;
							white-space: nowrap;

							>div {
								display: flex;
								align-items: center;
								justify-content: center;
								height: .7rem;
								background: linear-gradient(135deg, #53B6F4 0%, #1F8CFE 100%);
								border-radius: .1rem;
								font-weight: bold;
								font-size: .25rem;
								color: #FFFFFF;
								padding: 0 .25rem;
								cursor: pointer;

								&:last-child {
									margin-left: .25rem;
								}
							}
						}

						&:last-child {
							display: flex;
							align-items: center;
							margin-left: .375rem;

							>img {
								width: .6rem;
								height: .6rem;
								margin: 0 .2rem;
								cursor: pointer;
							}
						}
					}
				}
			}

			.record-baseinfo {
				width: 9.9625rem;
				height: 9rem;
				overflow: auto;
				background: #fff;
				border-radius: .1875rem;

				.video-call {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 6rem;
					height: 1.025rem;
					border-radius: 12.4875rem;
					font-weight: bold;
					font-size: .375rem;
					color: #FFFFFF;
					background: linear-gradient(to right, #53B6F4 0%, #1F8CFE 100%);
					margin: .625rem auto 0;
					cursor: pointer;

				}

				.record-header {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 1.975rem;
					height: .9rem;
					background: linear-gradient(180deg, #53B6F4 0%, #1F8CFE 100%);
					border-radius: .1875rem 0 .1875rem 0;
					font-weight: bold;
					font-size: .3rem;
					color: #FFFFFF;
				}

				.record-header2 {
					overflow: hidden;
					display: flex;
					align-items: center;
					width: 3.9375rem;

					.tabs {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 1.975rem;
						height: .9rem;
						font-weight: bold;
						font-size: .3rem;
						color: #FFFFFF;
						background: linear-gradient(180deg, #53B6F4 0%, #1F8CFE 100%);
						cursor: pointer;
					}

					.tab1 {
						border-radius: .1875rem 0 0 0;
					}

					.tab2 {
						border-radius: 0 0 .1875rem 0;
					}

					.tab_bg {
						background: #fff !important;
						color: #218CFF;
						border: 2px solid #218CFF;
					}
				}

				.record-title {
					display: flex;
					align-items: center;
					height: 1.025rem;
					font-weight: 800;
					font-size: .3rem;
					color: #333333;
					padding: 0 .375rem;
					border-bottom: .0125rem solid #EEEEEE;
				}

				.record-gap {
					width: 100%;
					height: .125rem;
					background: #F8F8F8;
				}

				.record-content {
					padding: .25rem .375rem;

					>div:not(:first-child) {
						margin-top: .25rem;
					}
				}

				.case-category {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: .7125rem;

					.el-input__inner {
						background-color: #F5F9FE;
						border: none;
					}

					>div {

						&:nth-child(2) {
							display: flex;
							align-items: center;
							flex: 1;
							margin: 0 .25rem;
							height: 100%;
							background-color: #F5F9FE;
							border-radius: .1rem;
						}

						&:last-child {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 1.5rem;
							height: 100%;
							background: linear-gradient(90deg, #53B6F4 0%, #1F8CFE 100%);
							border-radius: .1rem;
							font-weight: bold;
							font-size: .25rem;
							color: #FFFFFF;
							cursor: pointer;
						}
					}
				}

				.record-choose-date {
					display: flex;
					align-items: center;
					justify-content: space-between;

					>div {
						display: flex;
						align-items: center;
					}
				}
			}
		}

		.record-controls {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: .2rem;

			>div {

				&:first-child {
					display: flex;
					align-items: center;
					width: 16.3125rem;
					height: 1.3125rem;
					background: #fff;
					border-radius: .1875rem;
					display: flex;
					align-items: center;

					>div {
						flex: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						cursor: pointer;
						margin: 0 .125rem;

						>img {
							width: .525rem;
							height: .525rem;
						}

						>span {
							font-weight: bold;
							font-size: .22rem;
							color: #333333;
							margin-top: .125rem;
						}
					}
				}

				&:last-child {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 3.3375rem;
					height: 1.3125rem;
					background: #FFFFFF;
					border-radius: .1875rem;

					>span {
						&:first-child {
							font-size: .45rem;
							color: #218CFF;
						}

						&:last-child {
							font-size: .3rem;
							color: #333333;
							margin-top: .125rem;
						}
					}
				}
			}
		}

		.signature_box {
			position: absolute;
			bottom: -20px;
			left: 50%;
			transform: translate(-50%, 20%);
		}

		.signature_box_signature {
			width: 80px;
			height: 120px;
			object-fit: contain;
			transform: translate(-0%, 0%) rotate(-90deg);
			margin: 0 30px;
		}
	}
</style>